.jumbo-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  &::after {
    display: block;
    position: absolute;
    content: " ";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(50,50,50,0.5);
  }
  @include phablet {
    &::after {
      display: none;
    }
  }

}
.hero-cta {
  text-align: center;
  margin-bottom: 60px;
  padding-top: 170px;
  font-family: $secondary-font;
  z-index: 1;
  a.button:first-of-type {
    margin-bottom: 15px;
  }

  a.button:first-of-type {
    margin-right: 10px;
    @include mobile {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
  @include phablet {
      text-align: left;
  }
}
span.hero-txt-a, span.hero-txt-sub {
  display: block;
  font-size: 36px;
  line-height: 40px;
  font-weight: 700;
  color: $white;
  max-width: 320px;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;

  // @include mobile {
  //   max-width: 400px;
  //
  // }
  @include phablet {
    max-width: 450px;
    font-size: 50px;
    line-height: 60px;
    margin-left: 0;
    text-shadow: 1px 1px #000;
  }
  @include tablet {
    max-width: 570px;
  }
}

span.hero-txt-sub {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 25px;
  max-width: 340px;
  @include phablet {
    font-size: 24px;
    line-height: 34px;
    max-width: 560px;
  }
}
