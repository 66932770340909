.gallery-container {
  padding-bottom: 60px;
}

.gallery-content {
  max-width: 800px;
  @include desktop {
    max-width: 1024px;
  }
}

.page-gallery {
  @include desktop {
    margin-top: 0;
  }
  .hide-temp {
    display: none!important;
    @include tablet {
      display: block!important;
    }
  }
  .show-temp {
    display: block!important;
    @include tablet {
      display: none!important;
    }
  }
  .gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px -10px;
    .gallery-col {
      display: block;
      padding: 0 5px;
      margin-bottom: 10px;
      @include flex(0, 0, 50%);
      @include tablet {
         @include flex(0, 0, 33%);
      }
      @include laptop {
         @include flex(0, 0, 20%);
      }
      img {
        width: 100%;
      }
    }
  }
  .gallery-image {
    position: relative;
    display: block;
    @include aspect-ratio(4,3);
    background-size: cover;
    background-repeat: no-repeat;
    &.hide-image {
      display: none;
    }
    img {
      display: none;
    }
    &:after {
      content: '';
      @include stretch();
      background-color: rgba($black, 0.7);
      transition: $base-transition;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }
    .fa {
      transition: $base-transition;
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 25px;
      color: $white;
      will-change: opacity;
      visibility: hidden;
      opacity: 0;
    }
    &:focus,
    &:hover {
       &:after,
      .fa {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .open-gallery {
    @include aspect-ratio(1, 0.75);
    background-color: $brand-primary;
    transition: $base-transition;
    will-change: background-color;
    display: block;
    span {
      position: absolute;
      z-index: 300;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      text-align: center;
      top: 50%;
      font-size: 25px;
      color: $white;
      font-weight: 500;
      small {
        display: block;
      }
    }
    &:focus,
    &:hover {
      background-color: darken($brand-primary, 10%);
    }
  }
}
